import React, {Component} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

class Nav extends Component
{
    token = localStorage.getItem('token');
    config = {
        headers: {
            Authorization: 'Bearer ' + this.token
        }
    }

    logoutUser = async (e) => {
        localStorage.removeItem('token');
        this.props.navigate('/login');
        
        const res = await axios.post(process.env.REACT_APP_API + 'logout', this.state, this.config);
        if(res.data.status)
        {
            swal({
                title: "Success",
                text: res.data.message,
                icon: "success",
                button: "OK!",
                timer: 1500,
            });
        }

    }

    render () { 
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark mb-2 border-bottom border-secondary">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src='/logo192.png' style={{width:'30px',height:'30px'}} alt="xBaydin"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item dropdown">
                                <button className="btn nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-solid fa-user"></i>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                                    <li>
                                        <Link className="dropdown-item" to="/profile">Profile</Link>
                                    </li>
                                    <li>
                                        <button className="dropdown-item" onClick={this.logoutUser}>Logout</button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

// export default Navbar;
export default function Navbar(props) {
    return <Nav {...props} navigate={useNavigate()} />;
}