import React, {Component} from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Sidebar from './../../components/sidebar/Sidebar';
import Navbar from './../../components/Navbar';
import Footer from './../../components/Footer';

class EditUser extends Component
{
    state = {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        errors: '',
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async componentDidMount() {
        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            const id = this.props.params.id;
            const res = await axios.get(process.env.REACT_APP_API + 'users/' + id, config);

            if(res.data.status)
            {
                this.setState({
                    name: res.data.data.name,
                    email: res.data.data.email,
                })
            }
        }
    }

    saveUser = async (e) => {
        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            e.preventDefault();
            let btnSave = document.getElementById('btnSave');
            btnSave.disabled = true;
            btnSave.innerText = 'Saving...';
            
            const id = this.props.params.id;
            const res = await axios.put(process.env.REACT_APP_API + 'users/' + id, this.state, config);
            if(res.data.status)
            {
                // console.log(res.data.message);
                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    button: "OK!",
                    timer: 1500,
                });
                this.props.navigate('/user');
                this.setState({
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                })
            }
            else
            {
                this.setState({
                    errors: res.data.errors,
                })
            }
            btnSave.innerText = 'Save';
            btnSave.disabled = false;
        }
    }

    render() {
        return (
            <>
            <Sidebar/>
            <div id='main'>
                <Navbar/>
                <div className='container-fluid'>
                    <div className='card'>
                        <div className='card-header'>
                            <h4>Edit User
                                <Link to={'/user'} className='btn btn-primary btn-sm float-end'>
                                    <i className='fa-solid fa-arrow-left'></i> Back
                                </Link>
                            </h4>
                        </div>
                        <div className='card-body'>

                            <form onSubmit={this.saveUser}>
                                <div className='col-md-6 form-group mb-3'>
                                    <label>Name</label>
                                    <input type="text" name="name" onChange={this.handleInput} value={this.state.name || ''} className='form-control' />
                                    <span className='text-danger'>{this.state.errors.name}</span>
                                </div>
                                <div className='col-md-6 form-group mb-3'>
                                    <label>Email</label>
                                    <input type="text" name="email" onChange={this.handleInput} value={this.state.email || ''} className='form-control' />
                                    <span className='text-danger'>{this.state.errors.email}</span>
                                </div>
                                <div className='col-md-6 form-group mb-3'>
                                    <label>Password</label>
                                    <input type="password" name="password" onChange={this.handleInput} value={this.state.password || ''} className='form-control' />
                                    <span className='text-danger'>{this.state.errors.password}</span>
                                </div>
                                <div className='col-md-6 form-group mb-3'>
                                    <label>Confirm Password</label>
                                    <input type="password" name="password_confirmation" onChange={this.handleInput} value={this.state.password_confirmation || ''} className='form-control' />
                                    <span className='text-danger'>{this.state.errors.password_confirmation}</span>
                                </div>

                                <div className='col-md-6 form-group'>
                                    <button type='submit' id='btnSave' className='btn btn-primary'>
                                        <i className='fa-solid fa-floppy-disk'></i> Save
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            </>
        )
    }
}

export default function Edit(props) {
    return <EditUser {...props} navigate={useNavigate()}  params={useParams()} />;
}