import axios from 'axios';
import React, {Component} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import swal from 'sweetalert';

import Sidebar from './../../components/sidebar/Sidebar';
import Navbar from './../../components/Navbar';
import Footer from './../../components/Footer';

import BtnPublishTrue from './../../components/BtnPublishTrue';
import BtnPublishFalse from './../../components/BtnPublishFalse';

class GList extends Component
{
    state = {
        blogs: [],
        links: [],
        loading: true,
    }

    async componentDidMount() {
        this.getBlog(process.env.REACT_APP_API + 'blogs');
    }

    getBlog = async (url) => {
        this.setState({loading: true})

        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            const res = await axios.get(url, config)
            // .catch(error => { if(error.response.status === 401) localStorage.removeItem('token'); });

            if(res.data.status)
            {
                this.setState({
                    blogs: res.data.data.data,
                    links: res.data.data.links,
                    loading: false,
                })
            }
        }
    }

    setHome = async (e, item, index) => {

        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            const res = await axios.post(`${process.env.REACT_APP_API}blogs/${item.id}/home`, {home_display: !item.home_display}, config);
            if(res.data.status)
            {
                this.setState(state => ({
                    ...state,
                    blogs: state.blogs.map((x, i) => i === index ? res.data.data : x),
                }));

                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    button: "OK!",
                    timer: 1500,
                });
            }
        }

    }

    setPublish = async (e, item, index) => {

        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            const res = await axios.post(`${process.env.REACT_APP_API}blogs/${item.id}/publish`, {publish: !item.publish}, config);
            if(res.data.status)
            {
                this.setState(state => ({
                    ...state,
                    blogs: state.blogs.map((x, i) => i === index ? res.data.data : x),
                }));

                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    button: "OK!",
                    timer: 1500,
                });
            }
        }

    }

    deleteBlog = async (item, index) => {

        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            const res = await axios.delete(`${process.env.REACT_APP_API}blogs/${item.id}`, config);
            if(res.data.status)
            {
                this.state.blogs.splice(index, 1)
                this.setState(state => ({
                    ...state,
                    blogs: this.state.blogs,
                }));
                
                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    button: "OK!",
                    timer: 1500,
                });
            }
        }

    }

    confirmDeleteBlog = (e, item, index) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                this.deleteBlog(item, index);
            } else {
                swal({text:"Your record is safe!", timer: 1500,});
            }
        });
    }

    render() {

        var blog_html_table = '';
        var paging_html = '';

        if(this.state.loading)
        {
            blog_html_table = <tr><td colSpan='5'><h2>Loading...</h2></td></tr>
        }
        else
        {
            blog_html_table = 
            this.state.blogs.map((item, index) => {
                return (
                    <tr key={item.id}>
                        <td>
                            <div style={{width:'100px'}}>
                                <img src={item.image} alt='Blog' className='img-thumbnail'/>
                            </div>
                        </td>
                        <td>
                            <div style={{width:'130px'}}>
                                {item.en_title}
                                <br/>
                                {item.mm_title}
                            </div>
                        </td>
                        <td>
                            {item.mm_desc}
                        </td>
                        <td>
                            <div className='d-grid gap-2' style={{width:'50px'}}>
                                <button type='button' className='btn btn-md btn-outline-secondary' onClick={(e) => this.setPublish(e, item, index)}>{(item.publish) ? <BtnPublishTrue/> : <BtnPublishFalse/>}</button>

                                <Link to={`edit/${item.id}`} className='btn btn-success btn-md'>
                                    <i className='fa-solid fa-pen-to-square'></i>
                                </Link>
                                
                                <button type='button' onClick={(e) => this.confirmDeleteBlog(e, item, index)} className='btn btn-danger btn-md'>
                                    <i className='fa-solid fa-trash-can'></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                )
            })

            paging_html = 
            this.state.links.map((link) => {
                return (
                    <li key={link.label} className={"page-item" + (link.url ? '':' disabled ') + (link.active ? ' active ':'')}>
                        <button className="page-link" onClick={(e) => this.getBlog(link.url)}>
                            {link.label.replace('&laquo;','').replace('&raquo;','')}
                        </button>
                    </li>
                )
            })
        }

        return (
            <>
            <Sidebar/>
            <div id='main'>
                <Navbar/>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h4>Blog List
                                        <Link to={'add'} className='btn btn-primary btn-sm float-end'>
                                            <i className='fa-solid fa-plus'></i> Add Blog
                                        </Link>
                                    </h4>
                                </div>
                                <div className='card-body table-responsive'>

                                    <table className='table table-bordered table-striped table-hover'>
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>
                                                    EN/MM Title
                                                </th>
                                                <th>
                                                    MM Description
                                                </th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {blog_html_table}
                                        </tbody>
                                    </table>

                                    <nav><ul className="pagination justify-content-end">{paging_html}</ul></nav>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            </>
        )
    }
}

export default function List(props) {
    return <GList {...props} navigate={useNavigate()} />;
}