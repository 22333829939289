import React, {Component} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Sidebar from './../../components/sidebar/Sidebar';
import Navbar from './../../components/Navbar';
import Footer from './../../components/Footer';

class App extends Component
{
    state = {
        category_id: '',
        question_id: '',
        en_text: '',
        mm_text: '',
        categories_data: '',
        loading_categories_data: true,
        questions_data: '',
        loading_questions_data: true,
        errors: [],
    }

    async componentDidMount() {
        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            const res_category = await axios.get(process.env.REACT_APP_API + 'manomaya-categories', config);
            if(res_category.data.status)
            {
                this.setState({
                    categories_data: res_category.data.data,
                    loading_categories_data: false,
                })
            }
        }
    }

    handleInput = async (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

        if(e.target.name === 'category_id') {

            let token = await localStorage.getItem('token');
            if(!token) this.props.navigate('/login');
            else {
                let config = {headers: {Authorization: 'Bearer ' + token}}
                
                const res_que = await axios.get(process.env.REACT_APP_API + 'manomaya-questions/' + this.state.category_id, config);
                if(res_que.data.status)
                {
                    this.setState({
                        questions_data: res_que.data.data,
                        loading_questions_data: false,
                    })
                }
            }
        }
    }

    saveManomaya = async (e) => {
        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            e.preventDefault();
            let btnSave = document.getElementById('btnSave');
            btnSave.disabled = true;
            btnSave.innerText = 'Saving...';
            
            const res = await axios.post(process.env.REACT_APP_API + 'manomaya', this.state, config);
            if(res.data.status)
            {
                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    button: "OK!",
                    timer: 1500,
                });
                this.props.navigate('/manomaya');
                this.setState({
                    en_text: '',
                    mm_text: '',
                })
            }
            else
            {
                this.setState({
                    errors: res.data.errors,
                })
            }
            btnSave.innerText = 'Save';
            btnSave.disabled = false;
        }
    }

    render() {
        var category_select_box = '';
        if(!this.state.loading_categories_data)
        {
            category_select_box = 
            this.state.categories_data.map((item) => {
                return (
                    <option key={item.id} value={item.id}>{item.mm_text}</option>
                )
            })
        }
        var question_select_box = '';
        if(!this.state.loading_questions_data)
        {
            question_select_box = 
            this.state.questions_data.map((item) => {
                return (
                    <option key={item.id} value={item.id}>{item.mm_text}</option>
                )
            })
        }

        return (
            <>
            <Sidebar/>
            <div id='main'>
                <Navbar/>
                <div className='container-fluid'>
                    <div className='card'>
                        <div className='card-header'>
                            <h4>Add Manomaya
                                <Link to={'/manomaya'} className='btn btn-primary btn-sm float-end'>
                                    <i className='fa-solid fa-arrow-left'></i> Back
                                </Link>
                            </h4>
                        </div>
                        <div className='card-body'>

                            <form onSubmit={this.saveManomaya}>
                                <div className='row mb-3'>
                                <div className='col-md-6 form-group'>
                                    <label>Category</label>
                                    <select name='category_id' onChange={this.handleInput} value={this.state.category_id} className='form-control'>
                                        <option value=''>Select Category</option>
                                        {category_select_box}
                                    </select>
                                    <span className='text-danger'>{this.state.errors.category_id}</span>
                                </div>
                                <div className='col-md-6 form-group'>
                                    <label>Question</label>
                                    <select name='question_id' onChange={this.handleInput} value={this.state.question_id} className='form-control'>
                                        <option value=''>Select Question</option>
                                        {question_select_box}
                                    </select>
                                    <span className='text-danger'>{this.state.errors.question_id}</span>
                                </div>
                                </div>
                                <div className='row mb-3'>
                                <div className='col-md-6 form-group'>
                                    <label>MM Text</label>
                                    <textarea name="mm_text" onChange={this.handleInput} defaultValue={this.state.mm_text} className="form-control" rows="10"></textarea>
                                    <span className='text-danger'>{this.state.errors.mm_text}</span>
                                </div>
                                <div className='col-md-6 form-group'>
                                    <label>EN Text</label>
                                    <textarea name="en_text" onChange={this.handleInput} defaultValue={this.state.en_text} className="form-control" rows="10"></textarea>
                                    <span className='text-danger'>{this.state.errors.en_text}</span>
                                </div>
                                </div>

                                <div className='form-group'>
                                    <button type='submit' id='btnSave' className='btn btn-primary'>
                                        <i className='fa-solid fa-floppy-disk'></i> Save
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            </>
        )
    }
}

export default function Add(props) {
    return <App {...props} navigate={useNavigate()} />;
}