import React from "react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Login from './pages/Login';
import Dashboard from "./pages/Dashboard";

import ListUser from './pages/user/List';
import AddUser from './pages/user/Add';
import EditUser from './pages/user/Edit';

import ListCustomer from "./pages/customer/List";
import AddCustomer from "./pages/customer/Add";
import EditCustomer from "./pages/customer/Edit";

import ListCelebrity from "./pages/celebrity/List";
import AddCelebrity from "./pages/celebrity/Add";
import EditCelebrity from "./pages/celebrity/Edit";

import ListCategory from "./pages/category/List";
import AddCategory from "./pages/category/Add";
import EditCategory from "./pages/category/Edit";

import ListDefinition from "./pages/definition/List";
import AddDefinition from "./pages/definition/Add";
import EditDefinition from "./pages/definition/Edit";

import ListBlog from "./pages/blog/List";
import AddBlog from "./pages/blog/Add";
import EditBlog from "./pages/blog/Edit";

import ListManomaya from "./pages/manomaya/List";
import AddManomaya from "./pages/manomaya/Add";
import EditManomaya from "./pages/manomaya/Edit";

import Subscriber from './pages/subscriber/List';
import Contact from './pages/contact/List';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login/>} />
        <Route path='/' element={<Dashboard/>} />

        <Route path='/user/' element={<ListUser/>} />
        <Route path='/user/add' element={<AddUser/>} />
        <Route path='/user/edit/:id' element={<EditUser/>} />

        <Route path='/customer/' element={<ListCustomer/>} />
        <Route path='/customer/add' element={<AddCustomer/>} />
        <Route path='/customer/edit/:id' element={<EditCustomer/>} />

        <Route path='/celebrity/' element={<ListCelebrity/>} />
        <Route path='/celebrity/add' element={<AddCelebrity/>} />
        <Route path='/celebrity/edit/:id' element={<EditCelebrity/>} />

        <Route path='/category/' element={<ListCategory/>} />
        <Route path='/category/add' element={<AddCategory/>} />
        <Route path='/category/edit/:id' element={<EditCategory/>} />

        <Route path='/definition/' element={<ListDefinition/>} />
        <Route path='/definition/add' element={<AddDefinition/>} />
        <Route path='/definition/edit/:id' element={<EditDefinition/>} />
        
        <Route path='/blog/' element={<ListBlog/>} />
        <Route path='/blog/add' element={<AddBlog/>} />
        <Route path='/blog/edit/:id' element={<EditBlog/>} />
        
        <Route path='/manomaya/' element={<ListManomaya/>} />
        <Route path='/manomaya/add' element={<AddManomaya/>} />
        <Route path='/manomaya/edit/:id' element={<EditManomaya/>} />

        <Route path='/subscriber' element={<Subscriber/>} />

        <Route path='/contact' element={<Contact/>} />
      </Routes>
    </Router>
  );
}

export default App;
