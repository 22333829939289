import {Component} from 'react';
import {useNavigate} from 'react-router-dom';
import Sidebar from './../components/sidebar/Sidebar';
import Navbar from './../components/Navbar';
import Footer from './../components/Footer';

class GDashboard extends Component
{
    async componentDidMount() {
        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
    }

    render() {
        return (
            <>
            <Sidebar/>
            <div id='main'>
                <Navbar/>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h4>Dashboard</h4>
                                </div>
                                <div className='card-body'>

                                    <table className='table table-bordered table-striped'>
                                        <thead>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            </>
        )
    }
}

export default function Dashboard(props) {
    return <GDashboard {...props} navigate={useNavigate()} />;
}