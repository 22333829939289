import React, {Component} from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import Sidebar from './../../components/sidebar/Sidebar';
import Navbar from './../../components/Navbar';
import Footer from './../../components/Footer';

class GEdit extends Component
{
    state = {
        image: '',
        image_new: false,
        en_title: '',
        mm_title: '',
        en_desc: '',
        mm_desc: '',
        publish: true,
        errors: '',
    }

    async componentDidMount() {
        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            const id = this.props.params.id;
            const res = await axios.get(process.env.REACT_APP_API + 'blogs/' + id, config);

            if(res.data.status)
            {
                this.setState({
                    image: res.data.data.image,
                    en_title: res.data.data.en_title,
                    mm_title: res.data.data.mm_title,
                    en_desc: res.data.data.en_desc,
                    mm_desc: res.data.data.mm_desc,
                    publish: res.data.data.publish,
                })
            }
        }
    }

    handleInput = async (e) => {
        // console.log(e.target.name)
        if(e.target.name === 'image') {
            var file = e.target.files[0]
            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e) {
                this.setState({
                    image: reader.result,
                    image_new: true,
                })
            }.bind(this);
        } else if(e.target.name === 'publish') {
            this.setState({
                publish: !this.state.publish
            })
        } else {

            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    saveBlog = async (e) => {
        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            e.preventDefault();
            let btnSave = document.getElementById('btnSave');
            btnSave.disabled = true;
            btnSave.innerText = 'Saving...';
            
            const id = this.props.params.id;
            const res = await axios.put(process.env.REACT_APP_API + 'blogs/' + id, this.state, config);
            if(res.data.status)
            {
                // console.log(res.data.message);
                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    button: "OK!",
                    timer: 1500,
                });
                this.props.navigate('/blog');
            }
            else
            {
                this.setState({
                    errors: res.data.errors,
                })
            }
            btnSave.innerText = 'Save';
            btnSave.disabled = false;
        }
    }

    render() {
        return (
            <>
            <Sidebar/>
            <div id='main'>
                <Navbar/>
                <div className='container-fluid'>
                    <div className='card'>
                        <div className='card-header'>
                            <h4>Edit Blog
                                <Link to={'/blog'} className='btn btn-primary btn-sm float-end'>
                                    <i className='fa-solid fa-arrow-left'></i> Back
                                </Link>
                            </h4>
                        </div>
                        <div className='card-body'>

                            <form onSubmit={this.saveBlog}>
                                <div className='row mb-3'>
                                <div className='col-md-6 form-group'>
                                    <label>Image</label>
                                    <input type="file" name="image" className="form-control" onChange={this.handleInput}/>
                                    <span className='text-danger'>{this.state.errors.image}</span>
                                </div>
                                <div className='col-md-6' style={{width:'100px'}}>
                                    {this.state.image? <img src={this.state.image} alt='Blog' className='img-thumbnail'/>:''}
                                </div>
                                </div>
                                <div className='row mb-3'>
                                <div className='col-md-6 form-group'>
                                    <label>EN Title</label>
                                    <input type="text" name="en_title" onChange={this.handleInput} value={this.state.en_title} className='form-control' />
                                    <span className='text-danger'>{this.state.errors.en_title}</span>
                                </div>
                                <div className='col-md-6 form-group'>
                                    <label>MM Title</label>
                                    <input type="text" name="mm_title" onChange={this.handleInput} value={this.state.mm_title} className='form-control' />
                                    <span className='text-danger'>{this.state.errors.mm_title}</span>
                                </div>
                                </div>
                                <div className='row mb-3'>
                                <div className='col-md-6 form-group'>
                                    <label>EN Description</label>
                                    <textarea name="en_desc" onChange={this.handleInput} defaultValue={this.state.en_desc || ''} className="form-control" rows="10"></textarea>
                                    <span className='text-danger'>{this.state.errors.en_desc}</span>
                                </div>
                                <div className='col-md-6 form-group'>
                                    <label>MM Description</label>
                                    <textarea name="mm_desc" onChange={this.handleInput} defaultValue={this.state.mm_desc || ''} className="form-control" rows="10"></textarea>
                                    <span className='text-danger'>{this.state.errors.mm_desc}</span>
                                </div>
                                </div>
                                <div className='row mb-3'>
                                <div className='col-md-6 form-group'>
                                    <label>Publish</label>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" name="publish" id="flexSwitchCheckChecked" checked={this.state.publish} onChange={this.handleInput} value={this.state.publish || 0}/>
                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Publish this blog</label>
                                    </div>
                                </div>
                                </div>

                                <div className='form-group'>
                                    <button type='submit' id='btnSave' className='btn btn-primary'>
                                        <i className='fa-solid fa-floppy-disk'></i> Save
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            </>
        )
    }
}

export default function Edit(props) {
    return <GEdit {...props} navigate={useNavigate()}  params={useParams()} />;
}