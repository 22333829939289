import axios from 'axios';
import React, {Component} from 'react';
import {useNavigate} from 'react-router-dom';
import swal from 'sweetalert';
import Sidebar from './../../components/sidebar/Sidebar';
import Navbar from './../../components/Navbar';
import Footer from './../../components/Footer';

class GList extends Component
{
    state = {
        contacts: [],
        links: [],
        loading: true,
        btnDel: '',
    }

    async componentDidMount() {
        this.getContact(process.env.REACT_APP_API + 'contacts');
    }

    getContact = async (url) => {
        this.setState({loading: true})

        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            const res = await axios.get(url, config);
            if(res.data.status)
            {
                this.setState({
                    contacts: res.data.data.data,
                    links: res.data.data.links,
                    loading: false,
                })
            }
        }
    }

    deleteContact = async (id) => {

        const btnDel = this.state.btnDel;
        btnDel.innerText = 'Deleting...';

        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            const res = await axios.delete(`${process.env.REACT_APP_API}contacts/${id}`, config);
            if(res.data.status)
            {
                btnDel.closest('tr').remove();
                btnDel.innerText = 'Delete';
                // console.log(res.data.message);
                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    button: "OK!",
                    timer: 1500,
                });
            }
        }

    }

    confirmDeleteContact = (e, id) => {

        this.setState({
            btnDel: e.currentTarget
        })

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                this.deleteContact(id);
            } else {
                swal({text: "Your record is safe!", timer: 1500,});
            }
        });
    }

    render() {

        var contacts_html_table = '';
        var paging_html = '';

        if(this.state.loading)
        {
            contacts_html_table = <tr><td colSpan='4'><h2>Loading...</h2></td></tr>
        }
        else
        {
            contacts_html_table = 
            this.state.contacts.map((item) => {
                return (
                    <tr key={item.id}>
                        <td>{item.email}</td>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                        <td>
                            <button type='button' onClick={(e) => this.confirmDeleteContact(e, item.id)} className='btn btn-danger btn-md'>
                                <i className='fa-solid fa-trash-can'></i>
                            </button>
                        </td>
                    </tr>
                )
            })

            paging_html = 
            this.state.links.map((link) => {
                return (
                    <li key={link.label} className={"page-item" + (link.url ? '':' disabled ') + (link.active ? ' active ':'')}>
                        <button className="page-link" onClick={(e) => this.getContact(link.url)}>
                            {link.label.replace('&laquo;','').replace('&raquo;','')}
                        </button>
                    </li>
                )
            })
        }

        return (
            <>
            <Sidebar/>
            <div id='main'>
                <Navbar/>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h4>Contact List</h4>
                                </div>
                                <div className='card-body table-responsive'>

                                    <table className='table table-bordered table-striped table-hover'>
                                        <thead>
                                            <tr>
                                                <th>Email</th>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contacts_html_table}
                                        </tbody>
                                    </table>

                                    <nav><ul className="pagination justify-content-end">{paging_html}</ul></nav>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            </>
        )
    }
}

export default function List(props) {
    return <GList {...props} navigate={useNavigate()} />;
}