import React, {Component} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Sidebar from './../../components/sidebar/Sidebar';
import Navbar from './../../components/Navbar';
import Footer from './../../components/Footer';

class GAdd extends Component
{
    state = {
        en_name: '',
        mm_name: '',
        errors: [],
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    saveCategory = async (e) => {
        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            e.preventDefault();
            let btnSave = document.getElementById('btnSave');
            btnSave.disabled = true;
            btnSave.innerText = 'Saving...';
            
            const res = await axios.post(process.env.REACT_APP_API + 'categories', this.state, config);
            if(res.data.status)
            {
                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    button: "OK!",
                    timer: 1500,
                });
                this.props.navigate('/category');
                this.setState({
                    en_name: '',
                    mm_name: '',
                })
            }
            else
            {
                this.setState({
                    errors: res.data.errors,
                })
            }
            btnSave.innerText = 'Save';
            btnSave.disabled = false;
        }
    }

    render() {
        return (
            <>
            <Sidebar/>
            <div id='main'>
                <Navbar/>
                <div className='container-fluid'>
                    <div className='card'>
                        <div className='card-header'>
                            <h4>Add Category
                                <Link to={'/category'} className='btn btn-primary btn-sm float-end'>
                                    <i className='fa-solid fa-arrow-left'></i> Back
                                </Link>
                            </h4>
                        </div>
                        <div className='card-body'>

                            <form onSubmit={this.saveCategory}>
                                <div className='col-md-6 form-group mb-3'>
                                    <label>EN Name</label>
                                    <input type="text" name="en_name" onChange={this.handleInput} value={this.state.en_name} className='form-control' />
                                    <span className='text-danger'>{this.state.errors.en_name}</span>
                                </div>

                                <div className='col-md-6 form-group mb-3'>
                                    <label>MM Name</label>
                                    <input type="text" name="mm_name" onChange={this.handleInput} value={this.state.mm_name} className='form-control' />
                                    <span className='text-danger'>{this.state.errors.mm_name}</span>
                                </div>

                                <div className='col-md-6 form-group'>
                                    <button type='submit' id='btnSave' className='btn btn-primary'>
                                        <i className='fa-solid fa-floppy-disk'></i> Save
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            </>
        )
    }
}

export default function Add(props) {
    return <GAdd {...props} navigate={useNavigate()} />;
}