import axios from 'axios';
import React, {Component} from 'react';
import {useNavigate} from 'react-router-dom';
import swal from 'sweetalert';

class GLogin extends Component
{
    config = {
        headers: {
            Authorization: 'Bearer ' + this.token
        }
    }

    state = {
        email: '',
        password: '',
        errors: [],
    }

    async componentDidMount() {
        let token = await localStorage.getItem('token');
        if(token) {
            this.props.navigate('/');
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    loginUser = async (e) => {
        e.preventDefault();
        let btnLogin = document.getElementById('btnLogin');
        btnLogin.disabled = true;
        btnLogin.innerText = 'Logging in...';
        
        const res = await axios.post(process.env.REACT_APP_API + 'login', this.state, this.config);
        if(res.data.status)
        {
            // console.log(res.data.message);
            localStorage.setItem('token', res.data.token);
            swal({
                title: "Success",
                text: res.data.message,
                icon: "success",
                button: "OK!",
                timer: 1500,
            });
            this.props.navigate('/');
            this.setState({
                email: '',
                password: '',
            })
        }
        else
        {
            if(res.data.errors) {
                this.setState({
                    errors: res.data.errors,
                })
            } else {
                swal({
                    title: "Warning",
                    text: res.data.message,
                    icon: "warning",
                    buttons: "OK!",
                })
            }
        }
        btnLogin.innerText = 'Login';
        btnLogin.disabled = false;
    }

    render() {
        return (
            <div className='container'>
                <div className='row align-items-center justify-content-center' style={{minHeight: '100vh'}}>
                    <div className='col-md-6'>
                        <div className='card'>
                            <div className='card-header'>
                                <h4>Login</h4>
                            </div>
                            <div className='card-body'>
                                <form onSubmit={this.loginUser}>
                                    <div className='form-group mb-3'>
                                        <label>Email</label>
                                        <input type="text" name="email" onChange={this.handleInput} value={this.state.email} className='form-control' />
                                        <span className='text-danger'>{this.state.errors.email}</span>
                                    </div>
                                    <div className='form-group mb-3'>
                                        <label>Password</label>
                                        <input type="password" name="password" onChange={this.handleInput} value={this.state.password} className='form-control' />
                                        <span className='text-danger'>{this.state.errors.password}</span>
                                    </div>

                                    <div className='form-group mb-3'>
                                        <button type='submit' id='btnLogin' className='btn btn-primary'>Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default function Login(props) {
    return <GLogin {...props} navigate={useNavigate()} />;
}