import React, {Component} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import './../../components/sidebar/Sidebar.css';

class GSidebar extends Component
{
    menu = [
        {
            name: 'Dashboard',
            hasChild: false,
            link: '/',
            icon: 'fa-solid fa-table-columns',
        },
        {
            name: 'Data Entry',
            hasChild: true,
            link: '#',
            icon: 'fa-solid fa-database',
            child: [
                {
                    name: 'Celebrity',
                    hasChild: true,
                    link: '#',
                    child: [
                        {
                            name: 'DOB',
                            hasChild: false,
                            link: '/celebrity',
                        },
                    ]
                },
                {
                    name: 'Dictionary',
                    hasChild: true,
                    link: '#',
                    child: [
                        {
                            name: 'Category',
                            hasChild: false,
                            link: '/category',
                        },
                        {
                            name: 'Definition',
                            hasChild: false,
                            link: '/definition',
                        },
                    ]
                },
                {
                    name: 'Blog',
                    hasChild: false,
                    link: '/blog',
                },
                {
                    name: 'Manomaya',
                    hasChild: false,
                    link: '/manomaya',
                }
            ]
        },
        {
            name: 'Users',
            hasChild: true,
            link: '#',
            icon: 'fa-solid fa-users',
            child: [
                {
                    name: 'Admin',
                    hasChild: false,
                    link: '/user',
                },
                {
                    name: 'Customer',
                    hasChild: false,
                    link: '/customer',
                },
            ]
        }
    ]

    async componentDidMount() {
        // console.log(window.location.pathname)
    }

    getMenu = () => {
        let menuList = []
        this.menu.forEach((item, index) => {
            
            menuList.push(
                <Link key={index} to={item.link} aria-label={item.name} className={`btn` + (!item.hasChild?' nav-child-link ':'') + ((window.location.pathname === item.link) ? ' btn-primary text-light ':'')}>
                    <div className="sidebar-icon"><i className={item.icon}></i></div>
                    <div className="sidebar-text">{item.name}</div>
                </Link>
            )

            if(item.hasChild) {
                item.child.forEach((c1_item, c1_index) => {

                    menuList.push(
                        <Link key={index+'-c1-'+c1_index} to={c1_item.link} aria-label={c1_item.name} className={`btn nav-child-1` + (!c1_item.hasChild?' nav-child-link ':'') + ((window.location.pathname === c1_item.link) ? ' btn-primary text-light':'')}>
                            <div className="sidebar-icon"><i className="fa-solid fa-angles-right"></i></div>
                            <div className="sidebar-text">{c1_item.name}</div>
                        </Link>
                    )

                    if(c1_item.hasChild) {
                        c1_item.child.forEach((c2_item, c2_index) => {
                            // console.log(c2_item.name, index+'-'+c1_index+'-'+c2_index)

                            menuList.push(
                                <Link key={index+'-'+c1_index+'-'+c2_index} to={c2_item.link} aria-label={c2_item.name} className={`btn nav-child-2 nav-child-link ` + ((window.location.pathname === c2_item.link) ? ' btn-primary text-light':'')}>
                                    <div className="sidebar-icon"><i className="fa-solid fa-angle-right"></i></div>
                                    <div className="sidebar-text">{c2_item.name}</div>
                                </Link>
                            )

                        })
                    }

                })
            }
        })
        return menuList;
    }

    render () {
        return (
            <>
                <div id="mySideNav" className="custom-sidenav">
                    <button aria-label="Toggle Sidebar" className="btn close-btn" onClick={(e) => window.toggleNav()}>
                        <i className="fa-solid fa-bars"></i>
                    </button>
                    {this.getMenu()}
                </div>
            </>
        )
    }
}

export default function Sidebar(props) {
    return <GSidebar {...props} navigate={useNavigate()} />;
}