import React, {Component} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Sidebar from './../../components/sidebar/Sidebar';
import Navbar from './../../components/Navbar';
import Footer from './../../components/Footer';

class App extends Component
{
    state = {
        category_id: '',
        en_title: '',
        mm_title: '',
        en_desc: '',
        mm_desc: '',
        categories_data: '',
        loading_categories_data: true,
        errors: [],
    }

    async componentDidMount() {
        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            const res_category = await axios.get(process.env.REACT_APP_API + 'get-all-categories', config);
            if(res_category.data.status)
            {
                this.setState({
                    categories_data: res_category.data.data,
                    loading_categories_data: false,
                })
            }
        }
    }

    handleInput = async (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    saveDefinition = async (e) => {
        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            e.preventDefault();
            let btnSave = document.getElementById('btnSave');
            btnSave.disabled = true;
            btnSave.innerText = 'Saving...';
            
            const res = await axios.post(process.env.REACT_APP_API + 'definitions', this.state, config);
            if(res.data.status)
            {
                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    button: "OK!",
                    timer: 1500,
                });
                this.props.navigate('/definition');
                this.setState({
                    en_title: '',
                    mm_title: '',
                    en_desc: '',
                    mm_desc: '',
                })
            }
            else
            {
                this.setState({
                    errors: res.data.errors,
                })
            }
            btnSave.innerText = 'Save';
            btnSave.disabled = false;
        }
    }

    render() {
        var category_select_box = '';
        if(!this.state.loading_categories_data)
        {
            category_select_box = 
            this.state.categories_data.map((item) => {
                return (
                    <option key={item.id} value={item.id}>{item.mm_name}</option>
                )
            })
        }

        return (
            <>
            <Sidebar/>
            <div id='main'>
                <Navbar/>
                <div className='container-fluid'>
                    <div className='card'>
                        <div className='card-header'>
                            <h4>Add Definition
                                <Link to={'/definition'} className='btn btn-primary btn-sm float-end'>
                                    <i className='fa-solid fa-arrow-left'></i> Back
                                </Link>
                            </h4>
                        </div>
                        <div className='card-body'>

                            <form onSubmit={this.saveDefinition}>
                                <div className='row mb-3'>
                                <div className='col-md-6 form-group'>
                                    <label>Category</label>
                                    <select name='category_id' onChange={this.handleInput} value={this.state.category_id} className='form-control'>
                                        <option value=''>Select Category</option>
                                        {category_select_box}
                                    </select>
                                    <span className='text-danger'>{this.state.errors.category_id}</span>
                                </div>
                                </div>
                                <div className='row mb-3'>
                                <div className='col-md-6 form-group'>
                                    <label>EN Title</label>
                                    <input type="text" name="en_title" onChange={this.handleInput} value={this.state.en_title} className='form-control' />
                                    <span className='text-danger'>{this.state.errors.en_title}</span>
                                </div>
                                <div className='col-md-6 form-group'>
                                    <label>MM Title</label>
                                    <input type="text" name="mm_title" onChange={this.handleInput} value={this.state.mm_title} className='form-control' />
                                    <span className='text-danger'>{this.state.errors.mm_title}</span>
                                </div>
                                </div>
                                <div className='row mb-3'>
                                <div className='col-md-6 form-group'>
                                    <label>EN Description</label>
                                    <textarea name="en_desc" onChange={this.handleInput} defaultValue={this.state.en_desc} className="form-control" rows="20"></textarea>
                                    <span className='text-danger'>{this.state.errors.en_desc}</span>
                                </div>
                                <div className='col-md-6 form-group'>
                                    <label>MM Description</label>
                                    <textarea name="mm_desc" onChange={this.handleInput} defaultValue={this.state.mm_desc} className="form-control" rows="20"></textarea>
                                    <span className='text-danger'>{this.state.errors.mm_desc}</span>
                                </div>
                                </div>

                                <div className='form-group'>
                                    <button type='submit' id='btnSave' className='btn btn-primary'>
                                        <i className='fa-solid fa-floppy-disk'></i> Save
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            </>
        )
    }
}

export default function Add(props) {
    return <App {...props} navigate={useNavigate()} />;
}