import axios from 'axios';
import React, {Component} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import swal from 'sweetalert';
import Sidebar from './../../components/sidebar/Sidebar';
import Navbar from './../../components/Navbar';
import Footer from './../../components/Footer';

class GList extends Component
{
    state = {
        customers: [],
        loading: true,
        btnDel: '',
    }

    async componentDidMount() {
        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            const res = await axios.get(process.env.REACT_APP_API + 'customers', config);
            if(res.data.status)
            {
                this.setState({
                    customers: res.data.data,
                    loading: false,
                })
            }
        }
    }

    deleteCustomer = async (id) => {

        const btnDel = this.state.btnDel;
        btnDel.innerText = 'Deleting...';

        let token = await localStorage.getItem('token');
        if(!token) this.props.navigate('/login');
        else {
            let config = {headers: {Authorization: 'Bearer ' + token}}

            const res = await axios.delete(`${process.env.REACT_APP_API}customers/${id}`, config);
            if(res.data.status) 
            {
                btnDel.closest('tr').remove();
                btnDel.innerText = 'Delete';
                // console.log(res.data.message);
                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    button: "OK!",
                    timer: 1500,
                });
            }
        }

    }

    confirmDeleteCustomer = (e, id) => {

        this.setState({
            btnDel: e.currentTarget
        })

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                this.deleteCustomer(id);
            } else {
                swal({text: "Your record is safe!", timer: 1500,});
            }
        });
    }

    render() {
        var customer_html_table = '';
        if(this.state.loading)
        {
            customer_html_table = <tr><td colSpan='4'><h2>Loading...</h2></td></tr>
        }
        else
        {
            customer_html_table = 
            this.state.customers.map((item) => {
                return (
                    <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.phone}</td>
                        <td>
                            <Link to={`edit/${item.id}`} className='btn btn-success btn-md me-3'>
                                <i className='fa-solid fa-pen-to-square'></i>
                            </Link>
                            
                            <button type='button' onClick={(e) => this.confirmDeleteCustomer(e, item.id)} className='btn btn-danger btn-md'>
                                <i className='fa-solid fa-trash-can'></i>
                            </button>
                        </td>
                    </tr>
                )
            })
        }

        return (
            <>
            <Sidebar/>
            <div id='main'>
                <Navbar/>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h4>Customer List
                                        <Link to={'add'} className='btn btn-primary btn-sm float-end'>
                                            <i className='fa-solid fa-plus'></i> Add Customer
                                        </Link>
                                    </h4>
                                </div>
                                <div className='card-body table-responsive'>

                                    <table className='table table-bordered table-striped table-hover'>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {customer_html_table}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            </>
        )
    }
}

export default function List(props) {
    return <GList {...props} navigate={useNavigate()} />;
}