import React, {Component} from 'react';
import {useNavigate} from 'react-router-dom';

class Foot extends Component
{
    render () { 
        return (
            <footer className="mt-2 py-3 border-top border-secondary">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-muted">
                            <span>&copy; 2022 X-Baydin</span>
                            <span className="float-end">v1.0.0 <i className="fa-solid fa-code-branch"></i></span>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

// export default Footer;
export default function Footer(props) {
    return <Foot {...props} navigate={useNavigate()} />;
}